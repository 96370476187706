import React from "react";
import loadable from "@loadable/component";
const PageNotFoundAu = loadable(() => import("../../../components/shared/page-not-found-au"), {
    fallback: <PageLoader />
});
import withAULayout from "../../../components/au.mobile/layout";
import PageLoader from "../../../components/shared/page-loader";

const NotFound = () => {
    return <PageNotFoundAu />;
};

export default withAULayout(NotFound);
